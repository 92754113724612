import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
import moment from "moment";
const baseuri = "https://dmf5.xyz"
class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.privateGraphql.getnew
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.short}
          image={`${post.image}`}
          isBlogPost={true}
          datePublished={post.publishDate}
          authorName={post.profileName}
          authorUrl={`${siteUrl}/authors/${post.profileSlugName}`}
          authorSocial={Author.twitter}
        />
        <div className="row justify-content-center">
          <div className="col-md-12 col-xl-9 psm-0">
            <article className="w-layout-container--narrow w-post-content">
              <header className="w-article-header">
                <h1 className="w-article-header__headline">{post.title}</h1>
                <div className="w-authors__card">
                  <div className="w-author__image--row">
                    <div className="w-author__image--row-item">
                      {/* <img className="w-author__image w-author__image--small" src={baseuri + Author.image} alt="favicon" /> */}
                    </div>
                  </div>
                  <div className="media blog-post-author">
                    <LazyLoadImage
                      className="avatar avatar-xs"
                      title={Author.profilename}
                      src={baseuri + Author.image}
                      effect="blur"
                    />
                    <div className="media-body">
                      <small><Link to={`/authors/${Author.slugname}`}>{Author.profilename}</Link> - <a href={Author.twitter}>Twitter</a></small>
                      <small>{moment(post.publishDate).format('DD/MM/YYYY h:mm')}</small>
                    </div>
                  </div>
                  {/* <span className="w-author__name">
                      <Link to={`/authors/${Author.slugname}`}>{Author.profilename} </Link>
                    </span>
                    |
                    <span className="w-author__name">
                      <a href={`${Author.twitter}`}>
                         Twitter
                      </a>  
                    </span> */}
                </div>
              </header>
              <section dangerouslySetInnerHTML={{ __html: post.detail }} />
              <div className="w-card__chips w-chips">
                {post.slugtags !== null ?
                  post.slugtags.split(',').map(tagx => {
                    if (tagx !== "") return (
                      <Link to={`/${(tagx)}`} className="w-chip" key={tagx}>{tagx}</Link>
                    )
                  })
                  :
                  null
                }
              </div>
              <footer>
                {/* <Bio /> */}
              </footer>
            </article>
            <div className="container blog-relate">
              <Row className="justify-content-center text-center">
                <div className="col-12">
                  <span className="display-4">เรื่องอื่นๆ ที่น่าสนใจ</span>
                </div>
                <Col xs={12} md={6} className="item-relate">
                  {/* {previous && (
                    <div className="card">
                      <Link to={previous.slugtitle} title={previous.title} rel="prev">
                        <svg height="24px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-double-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-double-left fa-w-10 fa-3x"><path fill="currentColor" d="M153.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L192.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L153 264.5c-4.6-4.7-4.6-12.3.1-17zm-128 17l117.8 116c4.7 4.7 12.3 4.7 17 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L64.7 256l102.2-100.4c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L25 247.5c-4.6 4.7-4.6 12.3.1 17z" className=""></path></svg>
                        {previous.title}
                      </Link>
                    </div>
                  )} */}
                  {previous && (
                    <div className="card">
                      <Link to={previous.slugtitle} title={previous.title}>
                        <img src={baseuri + previous.image}
                          className="card-img-top" />
                      </Link>
                      <div className="card-body">
                        <h5 className="card-title">
                          <Link to={previous.slugtitle} title={previous.title}>{previous.title}</Link></h5>
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={12} md={6} className="item-relate">
                  {next && (
                    <div className="card">
                      <Link to={next.slugtitle} title={next.title}>
                        <img src={baseuri + next.image}
                          className="card-img-top" />
                      </Link>
                      <div className="card-body">
                        <h5 className="card-title">
                          <Link to={next.slugtitle} title={next.title}>{next.title}</Link>
                        </h5>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String = "404",$siteId: Int = 1051) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getauthorbynewslugtitle(slugtitle: $slug, siteId: $siteId) {
        id
        profilename
        twitter
        slugname
        image
        bio
      }
      getnew(slugtitle: $slug, siteId: $siteId) {
        id
        detail
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
      }
    }
  }
`
